import React from "react";
import ReadyUppy from "./posts/ready-uppy";

export default function Blog() {
    return (
        <>
            <ReadyUppy></ReadyUppy>
        </>
    )

}